import BaseBean from "@/utils/BaseBean";

export interface ICwjlListDataObj {
    utilInst:CwjlListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
    otherParams: any
}

export default class CwjlListUtil extends BaseBean{
    public dataObj:ICwjlListDataObj

    constructor(proxy:any,dataObj:ICwjlListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
    }
}